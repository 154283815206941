@import "assets/styles/common/colors.scss";
@import "~vgg-ui-react/dist/styles/index.scss";

.wallet-container {
  width: 100%;

  .page-title {
    margin-bottom: 32px;
    font-weight: bold;
    font-size: 24px;
    line-height: 16px;
  }

  .header-section {
    border: 1px solid $gray-060;
    padding-top: 36px;
    margin-bottom: 40px;
    position: relative;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #d7dce0;
      padding-bottom: 18px;

      .currency {
        width: 100%;
        padding-left: 24px;
      }

      .buttons-div {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        .w-130 {
          width: 29%;
          margin-right: 12px;

          .topup-btn {
            background-color: $primary-color;
            color: $pure-white;
            border: 1px solid $primary-color;
            border-radius: 3px;
            padding: 7px 19px;
            margin-right: 12px;
            border: 2px solid $primary-color;
            width: 100%;
            display: flex;
            justify-content: center;

            &.btn-small {
              font-size: 10px;
              padding: 7px 14px;
              border-width: 1px;
            }

            &:hover {
              background-color: $blue-400;
              border: 2px solid $blue-400;
            }

            &:focus {
              background-color: $primary-color;
              border: 2px solid $primary-color;
              box-shadow: none !important;
            }

            .topup-icon {
              width: 8px;
              margin-right: 5px;
              text-align: center;
            }
          }
          .convert-btn {
            background-color: $transparent;
            color: $primary-color;
            border-radius: 3px;
            padding: 7px 19px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            &.btn-small {
              font-size: 10px;
            }

            &:focus {
              box-shadow: none;
            }
            .convert-icon {
              width: 8px;
              margin-right: 5px;
              text-align: center;
            }
          }

          .withdraw-btn {
            background-color: $pure-white;
            color: $primary-color;
            border: 2px solid $primary-color;
            border-radius: 3px;
            padding: 7px 19px;
            width: 100%;
            display: flex;
            justify-content: center;

            &.btn-small {
              font-size: 10px;
              padding: 7px 14px;
              border-width: 1px;
            }

            &:hover {
              background: $blue-900;
              border: 2px solid $primary-color;
              color: $primary-color;
            }

            &:focus {
              background-color: $pure-white;
              border: 2px solid $primary-color;
              color: $primary-color;
              box-shadow: none !important;
            }

            .withdraw-icon {
              width: 8px;
              margin-right: 5px;
              text-align: center;
            }
          }
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        border-right: 1px solid $gray-060;
        height: 100%;
      }
    }

    .price-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }

    .price {
      margin-bottom: 24px;
      margin-top: 40px;
      font-weight: 600;
      font-size: 32px;
      line-height: 16px;
    }

    .half-eclipse {
      position: absolute;
      right: 3%;
      top: 15%;
      z-index: -1;
    }
  }

  .header-section-sm {
    padding: 16px 10px;

    .price {
      margin-bottom: 24px;
      margin-top: 40px;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .main {
    .table-container {
      overflow-x: auto;
    }
    .transact {
      font-weight: 500;
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 40px;
    }

    .icon-container {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary-color;
      background: $blue-900;
      border-radius: 3px;

      &.debited {
        color: $spr-500-color;
        background: $spr-100-color;
      }

      &.credited {
        color: $spb-500-color;
        background: $spb-100-color;
      }
    }
  }
}
