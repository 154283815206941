@import "assets/styles/common/colors.scss";

.topup-form-container {
  .note {
    background: $gray-050;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      margin: 0 17.33px;
    }

    &-content {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: 0.002em;

      color: $dark-150;
    }
  }

  .bank-detail {
    border-bottom: 1px solid $gray-100;

    .icon-container {
      background-color: $blue-900;
      color: $primary-color;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;

      &.number-icon {
        font-weight: 600;
        font-size: 9px;
      }
    }

    .detail-label,
    .detail-value {
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      letter-spacing: 0.4px;

      color: $dark-180;
    }

    .detail-value {
      font-weight: 500;
    }
  }

  .has-icon {
    position: relative;

    .form-control-feedback {
      position: absolute;
      z-index: 2;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: $gray-300;
      top: 36px;
      right: 7px;
    }
  }
}
