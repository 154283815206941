@import "assets/styles/common/colors.scss";

.wallet__container {
  width: 100%;
  padding: 1rem;

  .form-subtitle {
    line-height: 16px;
    color: $pure-black;
  }
}
