@import "assets/styles/common/colors.scss";

.withdrawal-form-container {
  .note {
    background: $gray-050;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      margin: 0 17.33px;
    }

    &-content {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: 0.002em;

      color: $dark-150;
    }
  }

  .mb-25 {
    margin-bottom: 2.5rem;
  }
  .mb-88 {
    margin-bottom: 4rem;
  }

  .otp-link {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .awaiting-verify {
    background: $blue-900 !important;
    border-color: $blue-900 !important;
    color: $primary-color !important;
  }
}
