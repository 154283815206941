// @import "remixicon/fonts/remixicon.css";
@import "assets/styles/bootstrap-override.scss";
@import "~vgg-ui-react/dist/styles/partials/colors.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: #1354d3 $spn-200-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $spn-200-color;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #1354d3;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #0e347e;
}

html {
  font-size: 16px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.spin {
  -webkit-animation: spin 0.2s infinite linear;
  animation: spin 0.2s infinite linear;
  display: inline-block;
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wallet-container,
.selectedCurrencySelect,
.currencySelector {
  .css-yk16xz-control {
    border: none !important;
  }

  .css-1pahdxg-control {
    border: none;
    cursor: pointer;
    box-shadow: none;

    &:hover {
      border-color: transparent;
      opacity: 7;
    }
  }

  .css-1uccc91-singleValue {
    font-size: 24px;
    font-weight: 700;
  }
}
