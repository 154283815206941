@import "assets/styles/common/colors.scss";
.convert-status-container {
  .status-header {
    font-size: 18px;
    font-weight: bold;
  }
  .status-body {
    font-size: 18px;
    color: $gray-400;
  }
}
