@import "assets/styles/common/colors.scss";
@import "~vgg-ui-react/dist/styles/index.scss";

.currencySelector {
  .topup-btn {
    background-color: $primary-color;
    color: $pure-white;
    border: 1px solid $primary-color;
    border-radius: 3px;
    padding: 7px 19px;
    border: 2px solid $primary-color;
    width: 100%;
    display: flex;
    justify-content: center;
    width: 10%;
    min-width: 212px;
    &.btn-small {
      font-size: 10px;
      padding: 7px 14px;
      border-width: 1px;
    }

    &:hover {
      background-color: $blue-400;
      border: 2px solid $blue-400;
    }

    &:focus {
      background-color: $primary-color;
      border: 2px solid $primary-color;
      box-shadow: none !important;
    }

    .topup-icon {
      width: 8px;
      margin-right: 5px;
      text-align: center;
    }
  }
}
